import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGauge, faFile, faVideo, faRightToBracket, faSignal, faUser } from "@fortawesome/free-solid-svg-icons";
import { handleUserLogout } from '../../../../utils/functions';
import "./index.css";

function DashboardLeftSideBar(){

    const navigate = useNavigate();

    return(
        <div className="col px-0 bg-primary left-sidebar">
            <div className="d-flex flex-column align-items-center align-items-sm-start pt-2 text-white min-vh-80">
                <ul className="nav nav-pills flex-column px-3 mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                    <li className="nav-item">
                        <NavLink to="/creator/dashboard" className="nav-link align-middle px-0">
                            <FontAwesomeIcon icon={faGauge} />
                            <span className="ms-1 d-none d-lg-inline"> Dashboard</span>
                            <span className="ms-1 d-none d-sm-inline d-lg-none"> Board</span>
                        </NavLink>
                    </li>
                    <hr className="sidebar-divider my-2 w-100" />
                    <li className="nav-item">
                        <NavLink to="/creator/members" className="nav-link align-middle px-0">
                            <FontAwesomeIcon icon={faUser} />
                            <span className="ms-1 d-none d-lg-inline"> Members</span>
                            <span className="ms-1 d-none d-sm-inline d-lg-none"> Mems</span>
                        </NavLink>
                    </li>
                    <hr className="sidebar-divider my-2 w-100" />
                    <li>
                        <NavLink to="/creator/documents" className="nav-link align-middle px-0">
                            <FontAwesomeIcon icon={faFile} />
                            <span className="ms-1 d-none d-lg-inline"> Documents</span>
                            <span className="ms-1 d-none d-sm-inline d-lg-none"> Docs</span>
                        </NavLink>
                    </li>
                    <hr className="sidebar-divider my-2 w-100" />
                    <li>
                        <NavLink to="/creator/videos" className="nav-link align-middle px-0">
                            <FontAwesomeIcon icon={faVideo} />
                            <span className="ms-1 d-none d-sm-inline"> Videos </span>
                        </NavLink>
                    </li>
                    <hr className="sidebar-divider my-2 w-100" />
                    <li>
                        <NavLink to="/creator/live-streams" className="nav-link align-middle px-0">
                            <FontAwesomeIcon icon={faSignal} />
                            <span className="ms-1 d-none d-lg-inline"> Live Streams</span>
                            <span className="ms-1 d-none d-sm-inline d-lg-none"> Stream</span>
                        </NavLink>
                    </li>
                    {/* <hr className="sidebar-divider my-2 w-100" />
                    <li>
                        <NavLink to="/creator/categories" className="nav-link align-middle px-0">
                            <FontAwesomeIcon icon={faBars} />
                            <span className="ms-1 d-none d-lg-inline"> Categories</span>
                            <span className="ms-1 d-none d-sm-inline d-lg-none"> Cats</span>
                        </NavLink>
                    </li> */}
                    <hr className="sidebar-divider my-2 w-100" />
                    <li>
                        <div className='cursor-pointer pt-2' onClick={() => handleUserLogout(navigate)}>
                            <FontAwesomeIcon icon={faRightToBracket} />
                            <span className="ms-1 d-none d-sm-inline"> Logout</span>
                        </div>
                        {/* <NavLink onClick={() => handleUserLogout(navigate)} className="nav-link align-middle px-0">
                            <FontAwesomeIcon icon={faRightToBracket} />
                            <span className="ms-1 d-none d-sm-inline"> Logout</span>
                        </NavLink> */}
                    </li>
                </ul>
                <hr />
            </div>
        </div>
    )
}

export default React.memo(DashboardLeftSideBar);