import Label from "../../../../../components/label";
import Input from "../../../../../components/input";
import DashboardRightSideBarContainer from "../container";
import {DATA_TYPES} from "../../../../../constants"

function Videos(){

    const addVideoFormFields = () => {
        return(
            <>
                <div className="mb-3">
                    <Label text="Title" for="title" />
                    <Input id="title" name="title" />
                </div>

                <div className="mb-3">
                    <Label text="URL" for="url" />
                    <Input id="url" name="url"/>
                </div>
            </>
        );
    }

    const viewVideoTableHeaders = ["Title", "URL"];

    return(
        <>
            <DashboardRightSideBarContainer type={DATA_TYPES.VIDEO} title="Video" addDataFormFields={addVideoFormFields()} viewDataTableHeaders={viewVideoTableHeaders}/>    
        </>
    )
}

export default Videos;