//import logo from './logo.svg';
import Input from '../../../components/input';
import Label from '../../../components/label';
//import Textarea from '../../../components/textarea';
import {handleCreatorRegistration, getFormAllFieldsValues, isAnyFieldEmpty, isStrongPassword, isUserLoggedIn} from "../../../utils/functions";
import Swal from 'sweetalert2';
import { messages } from '../../../utils/messages';
import { HTTP_STATUS_CODES, USER_ROLES } from '../../../constants';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contextProviders/auth';
import Loader from '../../../components/loader';

const Registration = () => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {currentUser} = useContext(AuthContext)

  useEffect(() => {
    if(isUserLoggedIn()){
      if(currentUser.role === USER_ROLES.CREATOR){
        navigate("/creator/dashboard");
      } else {
        navigate("/member/dasboard")
      }
    }
  }, [navigate, currentUser]);

  const handleError = (msg) => {
    Swal.fire({
        icon: 'error',
        title: msg,
        showConfirmButton: true,
        //timer: 1500
    });
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    const data = getFormAllFieldsValues(e);
    if(isAnyFieldEmpty(data)){
      return handleError(messages.error.allFieldsRequired);
    }  
    if(!isStrongPassword(data.password)){
      return handleError(messages.error.strongPassword);
    } 
    setIsLoading(true);
    const response = await handleCreatorRegistration(data);
    setIsLoading(false);
    if(response.status !== HTTP_STATUS_CODES.OK){
      Swal.fire({
          icon: 'error',
          title: response?.message || messages.error.message,
          showConfirmButton: true,
          //timer: 1500
      })
    } else {
      Swal.fire({
        icon: 'success',
        title: response?.message || messages.error.message,
        showConfirmButton: true,
        //timer: 1500
      })
    }
  }

  return (
    <div className="container-fluid min-vh-80">
      <div className="row">
        {/* Left Sidebar */}
        <div className="col-md-6 bg-primary text-white d-flex flex-column justify-content-center align-items-center p-4 text-center">
            <img src="https://madwix.com/wp-content/uploads/2023/09/mad-wix-logo.svg" className="img-fluid" style={{height:"45%"}} alt="madwix"/>

            <br /> <br />
         
          <p>Improve customer relationship to rise up your business & statistics.</p>
        </div>
        {/* Right Content */}
        <div className="col-md-6 d-flex flex-column justify-content-center">
          <div className="container">
            <div className="px-3 mb-5">
              <div className="">
                <h2 className="text-primary">Register for your dashboard</h2> <br />
                <h6>Already have an account ? <Link to="/creator/login">Login</Link></h6>  <br />
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <Label text="Name" for="name" />
                    <Input id="name" name="name" />
                  </div>
                  <div className="mb-3">
                    <Label text="Email" for="email" />
                    <Input type="email" id="email" name="email" />
                  </div>

                  {/* <div className="mb-3">
                    <Label text="username" for="username" />
                    <Input id="username" name="username" />
                  </div> */}

                  <div className="mb-3">
                    <Label text="Password" for="password" />
                    <Input type="password" id="password" name="password" />
                  </div>

                  {/* <div className="mb-3">
                    <Label text="Address" for="address"/>
                    <Textarea id="address" rows="5"/>
                  </div> */}

                  {isLoading ? <Loader /> :
                    <button type="submit" className="btn btn-primary">Submit</button>
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registration;