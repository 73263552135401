import { useCallback, useEffect, useState } from "react";
import { CONTENT_SOURCE, DATA_TYPES, PLAYER_FILE_URLS_PREFIX } from "../../../../constants";
import { getGoogleDriveFileId, getYouTubeVideoId } from "../../../../utils/functions";
import Loader from "../../../../components/loader";
import React from "react";

function MemberContentPlayer({content, dataType, viewingContent, setCurrentViewingContent}) {

    //console.log("MemberContentPlayer", content.current)

    const [isLoading, setIsLoading] = useState(true);

    const handleIframeLoad = useCallback(() => {
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if(viewingContent!==content.url){
            setIsLoading(true); // Set loading to true whenever content changes
        }
        setCurrentViewingContent(content.url);
    }, [content, viewingContent, setCurrentViewingContent]);

    const getPlayerContent = useCallback(() => {
        let contentSourceId;
        if(content.source === CONTENT_SOURCE.GOOGLE_DRIVE){
            contentSourceId = getGoogleDriveFileId(content.url)
        } else {
            contentSourceId = getYouTubeVideoId(content.url)
        }
        const contentURL = content.source === CONTENT_SOURCE.GOOGLE_DRIVE ? PLAYER_FILE_URLS_PREFIX.GOOGLE_DRIVE+contentSourceId : PLAYER_FILE_URLS_PREFIX.YOUTUBE+contentSourceId;

        if(content.source === CONTENT_SOURCE.GOOGLE_DRIVE){           
            if(dataType === DATA_TYPES.VIDEO){
                return(
                    <video width="100%" height="100%">
                        <source src={contentURL} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )
            } else {
                return(
                    <>
                        {isLoading && <div className="p-2 position-absolute w-100 top-50"><Loader /></div>}
                        <iframe id="myIframe" src={`${contentURL}#toolbar=0&embed=true`} width="100%" height="100%" onLoad={handleIframeLoad} title={content.title}></iframe>
                    </>
                )
            }
        } else if(content.source === CONTENT_SOURCE.YOUTUBE){
            return(
                <>
                    {isLoading && <div className="p-2 position-absolute w-100  top-50"><Loader /></div>}
                    <iframe width="100%" height="100%" src={contentURL} onLoad={handleIframeLoad} title={content.title}></iframe>
                </>
            )
        }
    }, [handleIframeLoad, content, dataType, isLoading]);

    return(
        <>

            {content?.source && content?.url 
                ? <> {getPlayerContent()} </>
                : <span>
                    <svg fill="#fff" viewBox="0 0 48 48"><path d="M0 0h48v48H0V0z" fill="none"></path><path d="M22 30h4v4h-4zm0-16h4v12h-4zm1.99-10C12.94 4 4 12.95 4 24s8.94 20 19.99 20S44 35.05 44 24 35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z" fillOpacity="0.7"></path></svg>
                    The content is not available.
                </span>
            }

        </>
    )
}

export default React.memo(MemberContentPlayer);