import {BrowserRouter as Router, Routes,  Route } from 'react-router-dom';
import Registration from '../../pages/creator/registration';
import Login from '../../pages/login';
import Dashboard from '../../pages/creator/dashboard';
import Menu from "../menu";
import Documents from '../../pages/creator/dashboard/right-sidebar/documents';
import Videos from '../../pages/creator/dashboard/right-sidebar/videos';
import LiveStreams from '../../pages/creator/dashboard/right-sidebar/liveStreams';
//import Categories from '../../pages/creator/dashboard/right-sidebar/categories';
import Members from '../../pages/creator/dashboard/right-sidebar/members';
import MemberDashboard from '../../pages/member/dashboard';
import MemberContent from '../../pages/member/dashboard/content';
import PrivateUserRoute from '../privateUserRoute';
import { USER_ROLES } from '../../constants';
import ProtectedRoutes from '../protectRoute';
import VerifyRegistration from '../../pages/creator/registration/verify';

function HeaderContainer() {

  return (
      <Router>
        <header className='vh-10'>
          <Menu />
        </header>
        <Routes>
              <Route path='/' element={<div className="min-vh-80"><h1>Hi</h1></div>} />

              <Route path='verify-email' element={<VerifyRegistration />} />

              <Route
                path="creator/login"
                element={<ProtectedRoutes Component={Login} user_role={USER_ROLES.CREATOR}/>}
              />

              <Route
                path="member/login"
                element={<ProtectedRoutes Component={Login} user_role={USER_ROLES.MEMBER}/>}
              />

              <Route
                path="creator/register"
                element={<ProtectedRoutes Component={Registration} user_role={USER_ROLES.CREATOR}/>}
              />

              <Route
                path="creator/dashboard"
                element={<PrivateUserRoute Component={Dashboard} user_role={USER_ROLES.CREATOR}/>}
              />  

              <Route
                path="creator/members"
                element={<PrivateUserRoute Component={Members} user_role={USER_ROLES.CREATOR}/>}
              />

              <Route
                path="creator/documents"
                element={<PrivateUserRoute Component={Documents} user_role={USER_ROLES.CREATOR}/>}
              />  

              <Route
                path="creator/videos"
                element={<PrivateUserRoute Component={Videos} user_role={USER_ROLES.CREATOR}/>}
              />

              <Route
                path="creator/live-streams"
                element={<PrivateUserRoute Component={LiveStreams} user_role={USER_ROLES.CREATOR}/>}
              />

              <Route path='member/dashboard' element = {<PrivateUserRoute Component={MemberDashboard} user_role={USER_ROLES.MEMBER}/>} />

              <Route path='member/documents' element = {<PrivateUserRoute Component={MemberContent} user_role={USER_ROLES.MEMBER} dataType="documents"/>} />

              <Route path='member/videos' element = {<PrivateUserRoute Component={MemberContent} user_role={USER_ROLES.MEMBER} dataType="videos"/>} />

              <Route path='member/streams' element = {<PrivateUserRoute Component={MemberContent} user_role={USER_ROLES.MEMBER} dataType="streams"/>} />
          
          </Routes>
      </Router>
  );
}

export default HeaderContainer;
