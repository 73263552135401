import { NavLink, useNavigate } from "react-router-dom";
import HeaderBanner from "./headerBanner";
import MemberDashboardContainer from "./container";
import { functionWrapperWithAuth, getAPIDataType, getContentsCount, handleUserLogout } from "../../../utils/functions";
import { useCallback, useEffect, useState } from "react";
import { DATA_TYPES, HTTP_STATUS_CODES } from "../../../constants";

function MemberDashboard(){

    const [contentCounts, setContentCounts] = useState({documents: 0, videos: 0, streams: 0});

    const navigate = useNavigate();

    const getDataCount = useCallback(async(dataType) => {
        const response = await functionWrapperWithAuth(() => getContentsCount(getAPIDataType(dataType)));
        if(response.status === HTTP_STATUS_CODES.OK){
            return response.data;
        } else if(response.status === HTTP_STATUS_CODES.INVALID_TOKEN){
            handleUserLogout(navigate);
        }
        return 0;
    }, [navigate]);

    const getCreatorContentCounts = useCallback(async() => {
        const documents = await getDataCount(DATA_TYPES.DOCUMENT) || 0;
        const videos = await getDataCount(DATA_TYPES.VIDEO) || 0;
        const streams = await getDataCount(DATA_TYPES.LIVE_STREAM) || 0;
        setContentCounts({documents, videos, streams})
    }, [getDataCount]);

    useEffect(() => {
        getCreatorContentCounts();
    }, [getCreatorContentCounts]); 

    const contentBoxesData = ["documents", "videos", "streams"];
    const contentBoxesColorClasses = ["primary", "success", "warning"];

    const getContentCardBoxes = () => {
        return(
            <MemberDashboardContainer>
            {contentBoxesData?.map((content, k) => {

                return(                    
                    <div className="col-10 col-sm-4 offset-sm-0 col-md-3" key={k}>
                        <NavLink to={contentCounts[contentBoxesData[k]] ? `/member/${content}`: ``} className="text-decoration-none">
                            <div className={`card border-left-${contentBoxesColorClasses[k]} shadow h-100 py-sm-4`}>
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className={`text-xs font-weight-bold 
                                            text-${contentBoxesColorClasses[k]} text-uppercase mb-1`}>
                                            {content} </div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{contentCounts[contentBoxesData[k]]}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                    </div>                
                )

            })}
            </MemberDashboardContainer>
        );  
    }

    return(
        <>
            <HeaderBanner />
            <div className="container-fluid vh-55">          
                <div className="row h-100 text-center offset-xs-1 justify-content-center align-items-center">
                    {getContentCardBoxes()}
                    {/* <div className="col-sm-4 col-md-3 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        Documents </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">0</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
    
                    {/* <div className="col-sm-4 col-md-3 mb-4">
                        <div className="card border-left-success shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Videos </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">0</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
    
                    {/* <div className="col-sm-4 col-md-3 mb-4">
                        <div className="card border-left-warning shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                            Live Streams </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">0</div>
                                    </div>
                                    {// <div className="col-auto">
                                       // <i className="fas fa-comments fa-2x text-gray-300"></i>
                                    //</div> 
                                    }
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default MemberDashboard;