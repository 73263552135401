import { useCallback, useEffect } from "react";
import CustomPagination from "../../../../components/customPagination";
import Table from "../../../../components/table";
import { useNavigate } from "react-router-dom";
import { deleteContent, functionWrapperWithAuth, getAPIDataType, handleUserLogout } from "../../../../utils/functions";
import ContentActionContainer from "./contentActionContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { DATA_TYPES, HTTP_STATUS_CODES } from "../../../../constants";
import { messages } from "../../../../utils/messages";
import Swal from "sweetalert2";

const ListingContent = (props) => {
    const {type, viewDataTableHeaders, handleError, limit, totalPages, getMyContents, contentData} = props;

    const navigate = useNavigate();
 
    const content = contentData?.data || [];

    //const currentPage = useRef(1);

    const handlePageChange = (page) => {
        // if(page === currentPage.current){ return; }
        // currentPage.current = page;
        if(page === contentData.page){ return; }
        getMyContents(type, page, limit);
    };


    const getTableHeaderContent = useCallback(() => {
        return(
            <tr>
                <th>Sr. No.</th>
                    {viewDataTableHeaders.map((d, k) => <th key={k}>{d}</th>)}
                <th>Action</th>
            </tr>
        );        
    }, [viewDataTableHeaders]);

    useEffect(() => {
        getMyContents(type, 1, limit);
    }, [getMyContents, type, limit])
    
    const deleteCreatorContent = useCallback(async(id) => {  
        const response = await functionWrapperWithAuth(() => deleteContent(id, getAPIDataType(type)));
        if(response.status === HTTP_STATUS_CODES.OK){
            Swal.fire({
                icon: 'success',
                title: response?.message || "",
                showConfirmButton: true,
                //timer: 1500
            })
            getMyContents(type, 1, limit);
        } else if(response.status === HTTP_STATUS_CODES.INVALID_TOKEN){
            handleUserLogout(navigate);
        } else {
            handleError(response?.message || messages.error.message);
        }
    }, [getMyContents, handleError, navigate, type, limit])

    const getTableBodyContent = () => {

        if(type === DATA_TYPES.VIDEO || type === DATA_TYPES.DOCUMENT){
            return(
                <>
                {content.map((content, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{content.title}</td>
                        <td>{content.url}</td>
                        <td>
                            <ContentActionContainer data={content} type={type}/> &nbsp;
                            <FontAwesomeIcon icon={faTrash} className="cursor-pointer" onClick={() => deleteCreatorContent(content.id)}/>
                        </td>
                    </tr>
                ))}
                </>
            );
        } else if(type === DATA_TYPES.LIVE_STREAM){
            return(
                <>
                {content.map((content, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{content.date}</td>
                        <td>{content.date}</td>
                        <td>{content.title}</td>
                        <td>{content.url}</td>
                        <td>
                            <ContentActionContainer data={content} type={type}/> &nbsp;
                            <FontAwesomeIcon icon={faTrash} className="cursor-pointer" onClick={() => deleteCreatorContent(content.id)}/>
                        </td>
                    </tr>
                ))}
                </>
            );
        } else if(type === DATA_TYPES.MEMBERS){
            return(
                <>
                {content.map((content, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{content.name}</td>
                        <td>{content.email}</td>
                        <td>
                            <ContentActionContainer data={content} type={type}/> &nbsp;
                            <FontAwesomeIcon icon={faTrash} className="cursor-pointer" onClick={() => deleteCreatorContent(content.id)}/>
                        </td>
                    </tr>
                ))}
                </>
            );
        }
    }

    return(
        <>
            <Table thead={getTableHeaderContent()} tbody={getTableBodyContent()}/>
            {totalPages > 1 &&
                <div className="justify-content-center pt-4">
                    <CustomPagination 
                        totalPages={totalPages}
                        currentPage={contentData.page}
                        onPageChange={handlePageChange}
                    />
                </div>
            }
        </>
    )
}

export default ListingContent;