import { NavLink, useNavigate } from 'react-router-dom';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import "./index.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ModalContainer from '../modal';
import Input from '../input';
import Label from '../label';
import { AuthContext } from '../../contextProviders/auth';
import { deleteCookieValue, functionWrapperWithAuth, getFormAllFieldsValues, getUserDetails, handleUserLogout, isAnyFieldEmpty, isStrongPassword, isUserLoggedIn, updateUserPassword } from '../../utils/functions';
import Swal from 'sweetalert2';
import { HTTP_STATUS_CODES } from '../../constants';
import { messages } from '../../utils/messages';
import { useLocation } from 'react-router-dom';

function Menu() {

    const location = useLocation();
    const isMemberRole = location.pathname.startsWith('/member');

    const NavbarCloseRef = useRef();
    const NavbarOffCanvasRef = useRef();

    const navigate = useNavigate();
    const [showChangePasswordPopup, setChangePasswordPopup] = useState(false);

    const {currentUser, setCurrentUser} = useContext(AuthContext);

    const handleChangePasswordPopup = () => {
      setChangePasswordPopup(!showChangePasswordPopup);
    }

    const getUserShortName = () => {
      let shortName = "U";
      if(currentUser?.name){
        const words = currentUser.name.split(' ');
        shortName = words.map(word => word[0]).join('').trim();
      }
      return currentUser?.name?.length < 20 ? currentUser?.name : shortName;
    }

    const getLoggedInUserDetails = useCallback(async() => {
      const response = await functionWrapperWithAuth(() => getUserDetails());
      if(response.status === HTTP_STATUS_CODES.OK && response?.data){
          setCurrentUser(response.data);
      } else if(response.status === HTTP_STATUS_CODES.NOT_FOUND){
          deleteCookieValue();
          window.location.reload();
      } else if(response.status === HTTP_STATUS_CODES.INVALID_TOKEN){
          deleteCookieValue();
      } else {
          Swal.fire({
              icon: 'error',
              title: response?.message || messages.error.message,
              showConfirmButton: true,
              //timer: 1500
          })
      }
    }, [setCurrentUser]);

  useEffect(() => {
    if(isUserLoggedIn()){
      getLoggedInUserDetails();
    }
  }, [getLoggedInUserDetails])

  const handleError = useCallback((msg) => {
    Swal.fire({
        icon: 'error',
        title: msg,
        showConfirmButton: true,
        //timer: 1500
    })
  }, []);

  const handleUpdatePasswordSubmit = async(e) => {
    e.preventDefault();

    const data = getFormAllFieldsValues(e);
    //Empty field validation
    if(isAnyFieldEmpty(data)){
        return handleError(messages.error.allFieldsRequired);
    }

    if(!isStrongPassword(data.password)){
      return handleError(messages.error.strongPassword);
    }

    const response = await functionWrapperWithAuth(() => updateUserPassword(data));
    if(response.status === HTTP_STATUS_CODES.OK){
        Swal.fire({
            icon: 'success',
            title: response?.message || "",
            showConfirmButton: true,
            //timer: 1500
        })
    } else if(response.status === HTTP_STATUS_CODES.INVALID_TOKEN){
        handleUserLogout(navigate);
    } else {
        handleError(response?.message || messages.error.message);
    }
    handleChangePasswordPopup();
  }

  const getContent = () => {
      return(
      <>
          <form onSubmit={handleUpdatePasswordSubmit}>
              <div className="mb-3">
                  <Label text="New Password" for="password" />
                  <Input id="password" type="password" name="password" />
              </div>

              <div className="text-center">
                <button type="submit" className="btn btn-primary btn-block mb-4">
                    Update
                </button>
              </div>
          </form>
      </>);
  }

  const expand="md";

  const closeNavbar = () => {
  
    if(NavbarCloseRef.current && NavbarOffCanvasRef.current?.backdrop){
      NavbarCloseRef.current.click();
    }
  }

  return (
      <>
        <ModalContainer title="Change Password" content={showChangePasswordPopup ? getContent(): ""} show={showChangePasswordPopup} handleClose={handleChangePasswordPopup} hideFooter={true}/>

        <Navbar collapseOnSelect key={expand} expand={expand} className="shadow-sm h-100">
          <Container fluid>
            <Navbar.Brand>
              {isMemberRole ? 
                <NavLink className="nav-link">CMS</NavLink>
                : 
                <NavLink to="/" className="nav-link">Madwix</NavLink>
              }
              
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} ref={NavbarCloseRef} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
              className="text-bg-primary"
              ref={NavbarOffCanvasRef}
            >
              <Offcanvas.Header closeButton className='border-bottom'>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3 flex-wrap">
                  {currentUser && isUserLoggedIn() && 
                    <NavDropdown
                        title={getUserShortName()}
                        id={`offcanvasNavbarDropdown-expand-${expand}`}
                      >
                        <NavLink className="nav-link px-2" onClick={() => {handleChangePasswordPopup(); closeNavbar();}}>
                          Change Password
                        </NavLink>
                        
                        <div onClick={() => {handleUserLogout(navigate, setCurrentUser); closeNavbar()}}>  
                          <NavLink className="nav-link bg-primary text-white px-2">Logout</NavLink>                                                        
                        </div>
                        
                    </NavDropdown>
                  }

                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </>
  );
}

export default Menu;
