import { DATA_TYPES } from '../../../../../constants';
import DashboardRightSideBarContainer from '../container';
import Label from '../../../../../components/label';
import Input from '../../../../../components/input';


const Documents = () => {
    const addDocumentFormFields = () => {
        return(
            <>
                <div className="mb-3">
                    <Label text="Title" for="title" />
                    <Input id="title" name="title" />
                </div>

                <div className="mb-3">
                    <Label text="URL" for="url" />
                    <Input id="url" name="url" />
                </div>
            </>
        );
    }  

    const viewDocumentTableHeaders = ["Title", "URL"];

    return (
        <>
            <DashboardRightSideBarContainer type = {DATA_TYPES.DOCUMENT} title="Document" addDataFormFields={addDocumentFormFields()} viewDataTableHeaders={viewDocumentTableHeaders}/>
        </>
    );
}

export default Documents;