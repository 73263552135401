import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ModalContainer from "../../../../components/modal";
import { useState, useRef } from "react";
import { CONTENT_SOURCE, DATA_TYPES, PLAYER_FILE_URLS_PREFIX } from "../../../../constants";
import { getYouTubeVideoId, getGoogleDriveFileId } from "../../../../utils/functions";
import Loader from "../../../../components/loader";

function ContentActionContainer (props) {
    const {data, type} = props;

    const content = useRef({title: "", url: "", source: ""});
    const [modalOption, setModalOption] = useState({
        show: false,
        loadingText: "Loading ..."
    });

    // const getYouTubeVideoId = (url) => {
    //     // Regular expression to match the YouTube video ID
    //     // const regExp = /^.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#\&\?]*).*/;
    //     const regExp = /^.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    //     const match = url.match(regExp);
      
    //     if (match && match[1].length === 11) {
    //       return match[1];
    //     } else {
    //       return '';
    //     }
    // }
      
    // const getGoogleDriveFileId = (url) => {
    //     // Regular expression to match the file ID in the URL
    //     const regex = /\/file\/d\/([a-zA-Z0-9_-]+)\//;
    //     const match = url.match(regex);
      
    //     if (match && match[1]) {
    //       // The file ID is captured in the first capturing group
    //       return match[1];
    //     } else {
    //       // URL format doesn't match or the file ID is not found
    //       return null;
    //     }
    // }

    const handleContentData = (data) => {
        const {title, url, source} = data;
        let contentSourceId;
        if(source === CONTENT_SOURCE.GOOGLE_DRIVE){
            contentSourceId = getGoogleDriveFileId(url)
        } else {
            contentSourceId = getYouTubeVideoId(url)
        }
        
        content.current = {
            title,
            url: source === CONTENT_SOURCE.GOOGLE_DRIVE ? PLAYER_FILE_URLS_PREFIX.GOOGLE_DRIVE+contentSourceId : PLAYER_FILE_URLS_PREFIX.YOUTUBE+contentSourceId,
            source
        }
        
        setModalOption({...modalOption, show: true});
    }

    const handleModalClose = () => {
        setModalOption({show: false, loadingText: "Loading ..."})
    }

    const getContent = () => {
        if(content.current.source === CONTENT_SOURCE.GOOGLE_DRIVE){           
            if(type === DATA_TYPES.VIDEO){
                return(
                    <video width="100%" height="100%">
                        <source src={content.current.url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )
            } else {
                return(
                    <>
                        {modalOption.loadingText && <Loader />}
                        <iframe id="myIframe" src={`${content.current.url}#toolbar=0&embed=true`} width="100%" height="600" onLoad={handleIframeLoad} title={content.current.title}></iframe>
                    </>
                )
            }
        } else {
            return(
                <>
                    {modalOption.loadingText && <Loader />}
                    <iframe width="100%" height="100%" onLoad={handleIframeLoad} src={content.current.url} title={content.current.title}></iframe>
                </>
            )
        }
    }

    const handleIframeLoad = () => {
        setModalOption({...modalOption, loadingText: ""})
    };
    
    return(
        <>
            {type!== DATA_TYPES.MEMBERS && 
                <FontAwesomeIcon icon={faEye} onClick={() => {handleContentData(data)}} className="cursor-pointer"/>
            } 

            {modalOption.show &&
                <ModalContainer title={content.current.title} content={modalOption.show ? getContent(): ""} show={modalOption.show} handleClose={handleModalClose}/>
            }
        </>
    )

}

export default ContentActionContainer;