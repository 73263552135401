import React, { useCallback } from "react";
import { convertDateTimeToEpoch, functionWrapperWithAuth, getAPIDataType, getFormAllFieldsValues, handleUserLogout, isAnyFieldEmpty, isStrongPassword, isValidGoogleDriveFileUrl, isValidYouTubeURL, saveContent } from "../../../../utils/functions";
import { CONTENT_SOURCE, CONTENT_TYPES, DATA_TYPES, HTTP_STATUS_CODES } from "../../../../constants";
import { messages } from "../../../../utils/messages";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const AddContentForm = React.memo((props) => {

    const {type, addDataFormFields, handleError, getMyContents, limit} = props;

    const navigate = useNavigate();

    const handleSubmit = useCallback(async(e) => {
        e.preventDefault();
        const data = getFormAllFieldsValues(e);
        //Empty field validation
        if(isAnyFieldEmpty(data)){
            return handleError(messages.error.allFieldsRequired);
        } 

        //File URL Validation
        if((type === DATA_TYPES.DOCUMENT && !isValidGoogleDriveFileUrl(data.url)) || (type === DATA_TYPES.VIDEO && (!isValidGoogleDriveFileUrl(data.url) && !isValidYouTubeURL(data.url)) ) || (type === DATA_TYPES.LIVE_STREAM && !isValidYouTubeURL(data.url))){
            return handleError(messages.error.invalidContentURL);
        } 
        
        //Member save strong password validation
        if(type === DATA_TYPES.MEMBERS && !isStrongPassword(data.password)){
            return handleError(messages.error.strongPassword);
        } 

        //Live stream future date time validation
        if(type === CONTENT_TYPES.LIVE_STREAM){
            data.start_time = convertDateTimeToEpoch(data.date, data.time);
            if(data.date < Math.floor(Date.now() / 1000)){
                return handleError(messages.error.dateTimePast);
            }
            if(isNaN(data.duration) || data.duration<1){
                return handleError(messages.error.invalidDuration);
            }
        }

        if(type === DATA_TYPES.DOCUMENT || type === DATA_TYPES.VIDEO || type === CONTENT_TYPES.LIVE_STREAM){
            data.source = CONTENT_SOURCE.GOOGLE_DRIVE;
            if(isValidYouTubeURL(data.url)){
                data.source = CONTENT_SOURCE.YOUTUBE;
            }
        }
        
        const response = await functionWrapperWithAuth(() => saveContent(data, getAPIDataType(type)));

        if(response.status === HTTP_STATUS_CODES.OK){
            Swal.fire({
                icon: 'success',
                title: response?.message || "",
                showConfirmButton: true,
                //timer: 1500
            })
            getMyContents(type, 1, limit);
        } else if(response.status === HTTP_STATUS_CODES.INVALID_TOKEN){
            handleUserLogout(navigate);
        } else {
            handleError(response?.message || messages.error.message);
        }                
        
    }, [getMyContents, handleError, navigate, type, limit]);

    return(
        <form onSubmit={handleSubmit}>
            {addDataFormFields}
            
            <button type="submit" className="btn btn-primary btn-block mb-4">
                Save
            </button>
        </form>
    )
});

export default React.memo(AddContentForm);