import Cookies from "js-cookie";
import { createContext, useState } from "react";

const defaultUserValue = {
    currentUser: "",
    setCurrentUser: () => {},
};

export const AuthContext = createContext({defaultUserValue});

export const AuthContextProvider = ({children}) => {

    const [currentUser, setCurrentUser] = useState({name:'', role:Cookies.get("role")});

    return(
        <>
            <AuthContext.Provider value={{currentUser, setCurrentUser}}>
                {children}
            </AuthContext.Provider>
        </>
    )
}