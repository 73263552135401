import CreatorDashboardContainer from "../container";
import {  DATA_TYPES, HTTP_STATUS_CODES } from "../../../../constants";
//import Pagination from 'react-bootstrap/Pagination';
import { functionWrapperWithAuth, handleUserLogout, getContents, getAPIDataType} from "../../../../utils/functions";
import { messages } from "../../../../utils/messages";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useCallback, useRef, useState } from "react";
import ListingContent from "./listingContent";
import AddContentForm from "./addContentForm";

function DashboardRightSideBarContainer(props){

    const navigate = useNavigate();
    const {type, title, addDataFormFields, viewDataTableHeaders} = props;

    const [content, setContent] = useState({page:1, data:[]})

    const limit = type === DATA_TYPES.LIVE_STREAM ? 10: 5;
    const totalPages = useRef(1);

    const setTotalPages = useCallback((total) => {
        totalPages.current = Math.ceil(total/limit);
    }, [limit]);

    const handleError = useCallback((msg) => {
        Swal.fire({
            icon: 'error',
            title: msg,
            showConfirmButton: true,
            //timer: 1500
        })
    }, []);

    const getMyContents = useCallback(async(type, page=1) => {
        const response = await functionWrapperWithAuth(() => getContents(getAPIDataType(type), page, limit));
        if(response.status === HTTP_STATUS_CODES.OK){
            if(page === 1){
                setTotalPages(response?.data?.total || 0);
            }
            setContent({page, data: response?.data?.lists || []});
        } else if(response.status === HTTP_STATUS_CODES.INVALID_TOKEN){
            handleUserLogout(navigate);
        } else {
            handleError(response?.message || messages.error.message);
        }
    }, [handleError, navigate, limit, setTotalPages]);

    return(
        <CreatorDashboardContainer>
            <div className="col-10 py-3">
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <div className="container-fluid">

                            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                <h1 className="h3 mb-0 text-gray-800">{type === DATA_TYPES.CATEGORY ? "Categories" : `${title}s`}</h1>
                            </div>
                            <hr />

                            <div className="row">

                                <div className="col-lg-4">
                     
                                    <div className="card mb-4">
                                        <div className="card-header">
                                            Add {title}
                                        </div>
                                        <div className="card-body">
                                            <AddContentForm type={type} addDataFormFields={addDataFormFields} handleError={handleError} getMyContents={getMyContents} limit={limit}/>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-8">

                                    <div className="card mb-4">
                                        <div className="card-header">
                                             Lists 
                                        </div>
                                        <div className="card-body justify-content-center">
                                            {    
                                                <ListingContent type={type} viewDataTableHeaders={viewDataTableHeaders} getMyContents={getMyContents} contentData={content} handleError={handleError} limit={limit} totalPages={totalPages.current} />  
                                            }
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>     
        </CreatorDashboardContainer>
    )
}

export default DashboardRightSideBarContainer;