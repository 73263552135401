import { DATA_TYPES } from '../../../../../constants';
import Label from '../../../../../components/label';
import Input from '../../../../../components/input';
import DashboardRightSideBarContainer from '../container';

const LiveStreams = () => {
    
    const addStreamFormFields = () => {
        return(
            <>
                <div className="mb-3">
                    <Label text="Title" for="title" />
                    <Input id="title" name="title" />
                </div>

                <div className="mb-3">
                    <Label text="Date" for="date" />
                    <Input id="date" name="date" type="date" />
                </div>

                {/* <div className='mb-3'>
                <DatePicker
                    //selected={selectedDate}
                    //onChange={handleDateChange}
                    //showTimeSelect
                    //timeFormat="HH:mm"
                    // timeIntervals={15}
                    // dateFormat="MMMM d, yyyy h:mm aa"
                    // timeCaption="Time"
                    dateFormat="MM/dd/yyyy"  // Customize the date format as needed
        showTimeSelect={false}  // Disable time selection

                    className='form-control'
                />

                </div> */}

                <div className="mb-3">
                    <Label text="Time" for="time" />
                    <Input id="time" type="time" name="time"/>
                </div>

                <div className="mb-3">
                    <Label text="Duration (in mins)" for="duration" />
                    <Input id="duration" name="duration" type="number" min="1"/>
                </div>

                <div className="mb-3">
                    <Label text="URL" for="url" />
                    <Input id="url" name="url"/>
                </div>
            </>
        );
    }

    const viewStreamTableHeaders = ["Title", "Date", "Time", "URL"];
    
    return (
        <>
            <DashboardRightSideBarContainer type={DATA_TYPES.LIVE_STREAM} title = "Stream" addDataFormFields={addStreamFormFields()} viewDataTableHeaders={viewStreamTableHeaders}/>
        </>
    );
}

export default LiveStreams;