import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contextProviders/auth';
//import { USER_ROLES } from '../../constants';
//import { isUserLoggedIn } from '../../utils/functions';
import { USER_ROLES } from '../../constants';
import { isUserLoggedIn } from '../../utils/functions';
const ProtectedRoutes = ({ Component, user_role}) => {
    const {currentUser} = useContext(AuthContext);
    return (
        <>
        {
         isUserLoggedIn() && currentUser?.role === USER_ROLES.CREATOR ? <Navigate to="/creator/dashboard" />
         : isUserLoggedIn() && currentUser.role === USER_ROLES.MEMBER ? (
            <Navigate to="/creator/dashboard" />
          ): 
          <Component user_role={user_role} />
        }
      </>
    );
}

export default ProtectedRoutes;