import { useCallback, useRef, useState } from "react";
import MemberDashboardContainer from "../container";
import { functionWrapperWithAuth, getContents, handleUserLogout } from "../../../../utils/functions";
import { HTTP_STATUS_CODES } from "../../../../constants";
import Swal from "sweetalert2";
import { messages } from "../../../../utils/messages";
import { useNavigate } from "react-router-dom";
import MemberContentPlayer from "./player";
import MemberContentData from "./data";
import "./index.css";

function MemberContent(props){

    const {dataType} = props;

    const limit = 12;
    const navigate = useNavigate();
    //const [isLoading, setIsLoading] = useState(false);
    const [viewContentData, setViewContentData] = useState({active:0, source: "", url: ""});
    const [hasMore, setHasMore] = useState(false);
    const content = useRef([]);
    const page = useRef(1);

    const viewingContent = useRef();

    const setCurrentViewingContent = (url) => {
        viewingContent.current = url;
    }

    //const pageLoaded = useRef(false);

    const setContent = useCallback((data, loadMore) => {
        content.current = loadMore? content.current = [...content.current, ...data]: [...data];
        
        // if(!pageLoaded?.current){
        //     pageLoaded.current = true;
        if(!loadMore){
            setViewContentData({active: 0, source: content?.current?.[0]?.source, url: content?.current?.[0]?.url})
        }
        //}
    }, []);

    const getMemberContents = useCallback(async(data={loadMore: false}) => {
        const response = await functionWrapperWithAuth(() => getContents(dataType, page.current, limit, data));

        if(response.status === HTTP_STATUS_CODES.OK){
            if(response?.data?.lists){
                setContent(response.data.lists, data?.loadMore);
                if(response?.data?.lists?.length>=limit){
                    setHasMore(true)
                    page.current++;
                } else {
                    setHasMore(false)
                }
            } else {
                setHasMore(false)
            }
        } else if(response.status === HTTP_STATUS_CODES.INVALID_TOKEN){
            handleUserLogout(navigate);
        } else {
            Swal.fire({
                icon: 'error',
                title: response?.message || messages.error.message,
                showConfirmButton: true,
                //timer: 1500
            })
        }

    }, [dataType, navigate, setContent]);

    const handleViewContentDataChange = (active, data) => {
        setViewContentData({active, source: data.source, url: data.url});
    }
    return(
        <MemberDashboardContainer>
            <div className="container-fluid vh-80 text-break">
                <div className="row h-100">
                    <div className="col-12 col-sm-8 px-0 position-relative grey-background d-flex align-items-center justify-content-center text-white">

                        {viewContentData &&
                            <MemberContentPlayer content = {viewContentData} dataType={dataType} setCurrentViewingContent={setCurrentViewingContent} viewingContent={viewingContent.current}/>
                        }
            
                    </div>
                    <div className="col-12 col-sm-4 px-0 overflow-y-auto" id="scrollableDiv">

                        <MemberContentData getMemberContents={getMemberContents} hasMore={hasMore} handleViewContentDataChange={handleViewContentDataChange} viewContentData={viewContentData} content={content.current} dataType={dataType}/>

                    </div>
                </div>
            </div>
        </MemberDashboardContainer>
    )
}

export default MemberContent