

function Footer() {
  return (
    <footer className="d-flex align-items-center justify-content-center shadow-sm p-3 bg-body border-top text-center vh-10">
        Copyright &copy; by Madwix
    </footer>
  );
}

export default Footer;
