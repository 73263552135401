import React, {useCallback, useRef, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble, faCheckToSlot } from "@fortawesome/free-solid-svg-icons";
import { useEffect} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../../components/loader";
import MemberContentDataTabs from "./tabs";
//import { debounce } from "../../../../utils/functions";
//import debounce from 'lodash/debounce'; // Import debounce from lodash

function MemberContentData(props){

    const {getMemberContents, hasMore, handleViewContentDataChange, viewContentData, content, dataType} = props;

    const tabs = ["live", "coming", "past"];

    const [activeTab, setActiveTab] = useState(tabs[0]);
    const [isLoading, setIsLoading] = useState(true);

    const pageLoaded = useRef(false);

    const handleTabChange = useCallback((tab) => {
        if(tab === "coming"){
            tab = "upcoming";
        }
        setActiveTab(tab)
    }, [setActiveTab])
    

    // const debouncedAPICall = useCallback(
    //     debounce(async (tab) => {
    //       setIsLoading(true); // Set loading to true immediately
    //       try {
    //         await getMemberContents(tab);
    //       } catch (error) {
    //         console.error("Error fetching data:", error);
    //       } finally {
    //         setIsLoading(false); // Reset loading state after API call completes
    //       }
    //     }, 1000),
    //     [getMemberContents, setIsLoading]
    // );
      
    
    // useEffect(()=> {
    //     console.log("checkisLoading", isLoading)
    // }, [isLoading])

    const getData = useCallback(async(request={loadMore: false}) => {
        setIsLoading(true);
        if(dataType === "streams"){
            if(!pageLoaded.current){
                pageLoaded.current = true;
                await getMemberContents({...request, type: activeTab})
            } else {
                //setIsLoading(true); // Set loading to true specifically for debounced call
                await getMemberContents({...request, type: activeTab});
            }
        } else {
            await getMemberContents({...request});
        }
        setIsLoading(false);
       //dataType === "streams" ? debouncedAPICall(activeTab) : getMemberContents();
    }, [dataType, activeTab, getMemberContents, setIsLoading]);

    useEffect(() => {
        getData();
    }, [getData, activeTab])

    console.log("contentdata", content)

    const formatContentDateTime = (timestamp) => {
        timestamp = timestamp*1000
        console.log("timestampvalue", timestamp)
        const timeStampDate = new Date(timestamp);
        const date = timeStampDate.getDate();
        const month = timeStampDate.getMonth()+1;
        const year = timeStampDate.getFullYear();
        let hours = timeStampDate.getHours();
        const minutes = timeStampDate.getMinutes();

        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12; // Set 12 for midnight
          

        return `${date}-${month}-${year} ${hours}:${minutes} ${ampm}`;
    } 

    return(
        <>
            {dataType === "streams" && <MemberContentDataTabs tabs={tabs} activeTab={activeTab} setActiveTab={handleTabChange} /> }
            {isLoading ?
                <Loader /> 
            :
                <InfiniteScroll
                    dataLength={content?.length}
                    next={() => getData({loadMore:true})}
                    hasMore={hasMore}
                    loader={<Loader />}
                    //pullDownToRefresh
                    //refreshFunction={() => getMemberContents()}
                    scrollableTarget="scrollableDiv"
                >
                    {content?.map((d, k) => {
                        let className = 'px-2 py-2 cursor-pointer border-bottom';
                        if(k===viewContentData.active){
                            className = className+' bg-primary text-white'
                        }
                        return <div className={className} key={k} onClick={() => handleViewContentDataChange(k, d)}>
                            <FontAwesomeIcon icon={faCheckDouble} /> 
                            &nbsp; <FontAwesomeIcon icon={faCheckToSlot} /> 
                            &nbsp; {d.title}

                            <br />
                            <small >{d?.start_time && <>{formatContentDateTime(d.start_time)}</>}</small>
                            </div>
                    })} 
                </InfiniteScroll>
            }
        </>
    )
}

export default React.memo(MemberContentData);