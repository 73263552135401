import {Modal, Button} from 'react-bootstrap';
//import { useState } from 'react';

function ModalContainer(props){
    
    const {show, title, handleClose, content, hideFooter} = props;

    return(
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton className='text-bg-primary'>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {content}
            </Modal.Body>
            {!hideFooter && 
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            }
        </Modal>
    )
}

export default ModalContainer;