import HeaderContainer from "../components/HeaderContainer";
import Footer from "../components/footer";
import { AuthContextProvider } from "../contextProviders/auth";
import Swal from "sweetalert2";

function IndexPage() {

  if(!navigator?.cookieEnabled){
    Swal.fire({
      icon: "error",
      text: "Cookie is disabled in your browser. Please enable the cookie to continue.",
      showConfirmButton: false,
    });
  }

  return (
    <>
      {navigator?.cookieEnabled && ( 
      <AuthContextProvider>
          <HeaderContainer />
          <Footer />
      </AuthContextProvider>
      )}
    </>
  );
}

export default IndexPage;
