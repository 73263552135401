import axios  from "axios";
import Cookies from "js-cookie";
import { messages } from "./messages";
import { DATA_TYPES, HTTP_STATUS_CODES, USER_ROLES } from "../constants";

const BACKEND_API_BASE_URL = process.env.REACT_APP_BACKEND_API_BASE_URL || "";
// const MAX_API_RETRY = 3;

export const handleCreatorRegistration = async(data) => {
    const response = {status: 0, message: ""};
    try{
        const res = await axios.post(`${BACKEND_API_BASE_URL}api/user/register`, data);
        response.status = res?.status;
        response.message = res?.data?.message || ""
    } catch(error){
        response.message = error?.response?.data?.message || messages?.error?.message;
        response.status = error?.response?.status;
    }
    return response;
}

export const handleUserLogin = async(data) => {
    const response = {status: 0, message: "", data: ""};
    try{
        const res = await axios.post(`${BACKEND_API_BASE_URL}api/user/login`, data)
        response.status = res?.status;
        response.data = res?.data?.data;
        setTokenvalue(res?.data?.data?.token, res?.data?.data?.role);
    } catch(error){
        response.message = error?.response?.data?.message || messages?.error?.message;
        response.status = error?.response?.status;
    }
    return response;
}

export const updateUserPassword = async(data) => {
    const response = {status: 0, message: ""};
    const options = {
        headers: { Authorization: getTokenValue() },
    };
    try{
        const res = await axios.post(`${BACKEND_API_BASE_URL}api/user/change-password`, data, options)
        response.status = res?.status;
        response.message = res?.data?.message;
    } catch(error){
        response.message = error?.response?.data?.message || messages?.error?.message;
        response.status = error?.response?.status;
    }
    return response;
}

export const saveContent = async(data, dataType="") => {
    const response = {status: 0, message: ""};
    const options = {
        headers: { Authorization: getTokenValue() },
    };
    try{
        const res = await axios.post(`${BACKEND_API_BASE_URL}api/${dataType}/save`, data, options);
        response.message = res?.data?.message;
        response.status = res?.status;
    } catch(error){
        response.message = error?.response?.data?.message || messages?.error?.message;
        response.status = error?.response?.status;
    }
    return response;
}

export const deleteContent = async(id, dataType="") => {
    const response = {status: 0, message: ""};
    const options = {
        headers: { Authorization: getTokenValue() },
    };
    try{
        const res = await axios.delete(`${BACKEND_API_BASE_URL}api/${dataType}/${id}`, options);
        response.message = res?.data?.message;
        response.status = res?.status;
    } catch(error){
        response.message = error?.response?.data?.message || messages?.error?.message;
        response.status = error?.response?.status;
    }
    return response;
}

export const isValidYouTubeURL = (url) => {
    // Regular expression for the specific YouTube URL format
    const youtubeRegExp = /^https:\/\/www\.youtube\.com\/watch\?v=[a-zA-Z0-9_-]+$/;

    return youtubeRegExp.test(url);
}

export const isValidGoogleDriveFileUrl = (url) => {
    // Regular expression to match Google Drive file URLs
    const googleDriveRegExp = /^https:\/\/drive\.google\.com\/file\/d\/[a-zA-Z0-9_-]+/;
  
    return googleDriveRegExp.test(url);
}
  
export const getYouTubeVideoId = (url) => {
    // Regular expression to match the YouTube video ID
    // const regExp = /^.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#\&\?]*).*/;
    const regExp = /^.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
  
    if (match && match[1].length === 11) {
      return match[1];
    } else {
      return '';
    }
}

export const getGoogleDriveFileId = (url) => {
    // Regular expression to match the file ID in the URL
    const regex = /\/file\/d\/([a-zA-Z0-9_-]+)\//;
    const match = url.match(regex);
  
    if (match && match[1]) {
      // The file ID is captured in the first capturing group
      return match[1];
    } else {
      // URL format doesn't match or the file ID is not found
      return null;
    }
}

export const getFormAllFieldsValues = (event) => {
    const formData = new FormData(event.target);
    const formDataObject = {};

    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });
    return formDataObject;
}

export const isAnyFieldEmpty = (data) => {
    return Object.values(data).some((value) => value === '' || value === null || value === undefined);
} 

export const isUserLoggedIn = () => {
    return getTokenValue();
}

const getTokenValue = () => {
    return Cookies.get("token");
}

const setTokenvalue = (token, role='') => {
    Cookies.set("role", role);
    Cookies.set("token", token)
}

const refreshAuthToken = async() => {
    const options = {
        headers: { Authorization: getTokenValue() },
    };
    const res = await axios.post(`${BACKEND_API_BASE_URL}api/user/refreshToken`, "", options);
    setTokenvalue(res?.data?.data?.token);
}

export const functionWrapperWithAuth = async(fun, ...params) => {
    // let response;
    // if(retry < MAX_API_RETRY){
    //     response = await fun(...params);
    //     if(response.status == HTTP_STATUS_CODES.UNAUTHORIZED){
    //         retry++;
    //         return functionWrapperWithAuth(retry, fun, ...params)
    //     }
    // }
    // return response

    if(!getTokenValue()){
        return {status: HTTP_STATUS_CODES.INVALID_TOKEN};
    }

    const MAX_API_RETRY = 4; // Set the default maximum retry value
    return async function inner(retry = 0) {
        let response;
        if (retry < MAX_API_RETRY) {
            response = await fun(...params);
            if (response.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
                retry++;
                await refreshAuthToken();
                return inner(retry); // Recursive call with retry
            }
        }
        return response;
    }();
}

export const isStrongPassword = (password)  => {
    const lengthRule = password.length >= 8;
    const containsUppercase = /[A-Z]/.test(password);
    const containsLowercase = /[a-z]/.test(password);
    const containsDigits = /\d/.test(password);
    //const specialCharacterRegex = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g;
    const specialCharacterRegex = /^[a-zA-Z0-9!@#$%^&*()_+\-.=]+$/g;
    const containsSpecial = specialCharacterRegex.test(password);
  
    // Check if the password meets all the defined rules
    return (
      lengthRule &&
      containsUppercase &&
      containsLowercase &&
      containsDigits &&
      containsSpecial
    );
}

export const convertDateTimeToEpoch = (dateString, timeString) => {
    // Split the date into year, month, and day
    const [year, month, day] = dateString.split('-').map(Number);
  
    // Split the time into hours and minutes
    const [hours, minutes] = timeString.split(':').map(Number);
  
    // Create a Date object using the date and time components
    const dateObject = new Date(year, month - 1, day, hours, minutes);
  
    // Get the timestamp in milliseconds
    const epochTimeInMilliseconds = dateObject.getTime();
  
    // Convert to seconds by dividing by 1000
    const epochTimeInSeconds = epochTimeInMilliseconds / 1000;
  
    return epochTimeInSeconds;
}

export const verifyUser = async(token) => {
    const response = {message: "", status: ""}
    try{
        const res = await axios.get(`${BACKEND_API_BASE_URL}api/user/verify-email?token=${token}`);
        response.message = res?.data?.message;
        response.status = res?.status;
    } catch(error){
        response.message = error?.response?.data?.message || messages?.error?.message;
        response.status = error?.response?.status;
    }
    return response;
}

export const handleUserLogout = (navigate) => {
    let user_role = USER_ROLES.MEMBER;
    if(Cookies.get("role") === USER_ROLES.CREATOR){
        user_role = USER_ROLES.CREATOR;
    }
    deleteCookieValue();
    navigate(`/${user_role}/login`);
}

export const deleteCookieValue = () => {
    Cookies.remove("role");
    Cookies.remove("token");
}
  
export const getUserDetails = async() => {
    const response = {status: 0, message: "", data: ""};
    const options = {
        headers: { Authorization: getTokenValue() },
    };
    try{
        const res = await axios.get(`${BACKEND_API_BASE_URL}api/user/details`, options);
        response.data = res?.data?.data;
        response.message = res?.data?.message;
        response.status = res?.status;
    } catch(error){
        response.message = error?.response?.data?.message || messages?.error?.message;
        response.status = error?.response?.status;
    }
    return response;
}

export const getContents = async(dataType="", page=1, limit=5, requestData) => {
    const response = {status: 0, message: "", data: ""};
    const options = {
        headers: { Authorization: getTokenValue() },
    };
    try{
        const params = requestData?.type ? {limit, page, type: requestData?.type}: {limit, page}
        const res = await axios.get(`${BACKEND_API_BASE_URL}api/${dataType}`, {...options, params});
        response.data = res?.data?.data;
        response.message = res?.data?.message;
        response.status = res?.status;
    } catch(error){
        response.message = error?.response?.data?.message || messages?.error?.message;
        response.status = error?.response?.status;
    }
    return response;
}

export const getContentsCount = async(dataType) => {
    const response = {status: 0, message: "", data: ""};
    const options = {
        headers: { Authorization: getTokenValue() },
    };
    try{
        const res = await axios.get(`${BACKEND_API_BASE_URL}api/${dataType}/count`, options);
        response.data = res?.data?.data;
        response.message = res?.data?.message;
        response.status = res?.status;
    } catch(error){
        response.message = error?.response?.data?.message || messages?.error?.message;
        response.status = error?.response?.status;
    }
    return response;
}

export const getAPIDataType = (type) => {
    return type === DATA_TYPES.DOCUMENT ? "documents": type === DATA_TYPES.VIDEO ? "videos" : type === DATA_TYPES.LIVE_STREAM ? "streams": type === DATA_TYPES.MEMBERS ? "members": type === DATA_TYPES.CATEGORY ? "categories": "";
};

export const getQueryStringValue = (key) => {
    const queryString = window.location.search;
    // Parse the query string to extract parameters
    const params = new URLSearchParams(queryString);
    return params.get(`${key}`);
}

// export const debounce = (func, delay) => {
//     let timeoutId;
//     return function (...args) {
//       clearTimeout(timeoutId);
//       timeoutId = setTimeout(() => func.apply(this, args), delay);
//     };
// };

export const debounce = (func, delay) => {
  let timeoutId;
  
  return function (...args) {
    const immediate = !timeoutId;

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      timeoutId = null;
      if (!immediate) {
        func.apply(this, args);
      }
    }, delay);

    if (immediate) {
      func.apply(this, args);
    }
  };
};