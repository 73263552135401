function HeaderBanner(){
    return(
        <>
            <div className="container-fluid bg-primary text-white vh-25 text-center">
                <div className="row h-100 justify-content-center align-items-center"><h1>My contents</h1></div>
            </div>
        </>
    )
}

export default HeaderBanner;