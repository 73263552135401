import React from "react";
import DashboardLeftSideBar from "./left-sidebar";

function CreatorDashboardContainer({children}){
    return(
        <>
            <div className="container-fluid">
                <div className="row flex-nowrap">
                    <DashboardLeftSideBar />
                    {children}
                </div>
            </div>
        </>
    )
}

export default React.memo(CreatorDashboardContainer);