import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contextProviders/auth';
//import { USER_ROLES } from '../../constants';
import { isUserLoggedIn } from '../../utils/functions';
import { USER_ROLES } from '../../constants';
const PrivateUserRoute = ({ Component, user_role, ...rest}) => {
    const {currentUser} = useContext(AuthContext);
    return (
      <>
        {
         isUserLoggedIn() && currentUser?.role === user_role ? <Component {...rest} />
         : isUserLoggedIn() && currentUser?.role === USER_ROLES.MEMBER ? <Navigate to="/member/dashboard" /> : <Navigate to="/creator/login" />
        }
      </>
    );
}

export default PrivateUserRoute;