import { useEffect } from "react";
import { isUserLoggedIn } from "../../../utils/functions";
import { useNavigate } from "react-router-dom";
//import InfiniteScroll from 'react-infinite-scroll-component';

const MemberDashboardContainer = ({children}) => {

    const navigate = useNavigate();

    useEffect(() => {
        if(!isUserLoggedIn()){
            navigate("/member/login");
        }
    }, [navigate])

    return(
        <>
            {children}
        </>
    )
}

export default MemberDashboardContainer;