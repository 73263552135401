import { useCallback, useEffect, useState } from 'react';
import CreatorDashboardContainer from './container';
import { functionWrapperWithAuth, getAPIDataType, getContentsCount, handleUserLogout } from '../../../utils/functions';
import { DATA_TYPES, HTTP_STATUS_CODES } from '../../../constants';
import { Link, useNavigate } from 'react-router-dom';
import "./index.css"

const Dashboard = () => {

  const [contentCounts, setContentCounts] = useState({documents: 0, videos: 0, streams: 0, members: 0});

  const navigate = useNavigate();

  const getDataCount = useCallback(async(dataType) => {
    const response = await functionWrapperWithAuth(() => getContentsCount(getAPIDataType(dataType)));
    if(response.status === HTTP_STATUS_CODES.OK){
        return response.data;
    } else if(response.status === HTTP_STATUS_CODES.INVALID_TOKEN){
        handleUserLogout(navigate);
    }
    return 0;
  }, [navigate]);

  const getCreatorContentCounts = useCallback(async() => {
    const documents = await getDataCount(DATA_TYPES.DOCUMENT) || 0;
    const videos = await getDataCount(DATA_TYPES.VIDEO) || 0;
    const streams = await getDataCount(DATA_TYPES.LIVE_STREAM) || 0;
    const members = await getDataCount(DATA_TYPES.MEMBERS) || 0;
    setContentCounts({documents, videos, streams, members})
  }, [getDataCount]);

  useEffect(() => {
    getCreatorContentCounts();
  }, [getCreatorContentCounts]); 

  const contentBoxesData = ["documents", "videos", "streams"];
  const contentBoxesColorClasses = ["primary", "success", "warning"];

  return (
    <>
        <CreatorDashboardContainer >
        <div className="col-10 py-3">
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content" className='dashboard-content'>
                    <div className="container-fluid">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                        </div>
                        <div className="row text-center">
                            {contentBoxesData?.map((content, k) => {
                                return(                    
                                <div className="col-sm-4 col-md-4 mb-4" key={k}>
                                    <Link to={`/creator/${contentBoxesData[k] === "streams" ? "live-streams": contentBoxesData[k]}`}>
                                        <div className={`card border-left-${contentBoxesColorClasses[k]} shadow h-100 py-2`}>
                                            <div className="card-body">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col mr-2">
                                                        <div className={`text-xs font-weight-bold text-${contentBoxesColorClasses[k]} text-uppercase mb-1`}>
                                                        {content} (Total)</div>
                                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{contentCounts[contentBoxesData[k]]}</div>
                                                    </div>
                                                    {/* <div className="col-auto">
                                                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>    
                                </div>                
                                )

                            })}
                        </div>

                        <div className="row text-center">
                            <div className="col-md-4 offset-md-4 mb-4">
                                <Link to="/creator/members">
                                    <div className="card border-left-info shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                                                        Members (Total)</div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{contentCounts.members}</div>
                                                </div>
                                                {/* <div className="col-auto">
                                                    <i className="fas fa-comments fa-2x text-gray-300"></i>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </CreatorDashboardContainer>
    </>
  );
}

export default Dashboard;