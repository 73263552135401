function Table(props){

    return(
        <div className="table-responsive">
            <table className="table table-bordered table-striped text-center">
                <thead>
                    {props.thead}
                </thead>
                <tbody>
                    {props.tbody}
                </tbody>
            </table>
        </div>
    )
}

export default Table;