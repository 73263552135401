import Label from "../../../../../components/label";
import Input from "../../../../../components/input";
import DashboardRightSideBarContainer from "../container";
import { DATA_TYPES } from "../../../../../constants";

function Members(){

    const addMemberFormFields = () => {
        return(
            <>
                <div className="mb-3">
                    <Label text="Name" for="name" />
                    <Input id="name" name="name" />
                </div>

                <div className="mb-3">
                    <Label text="Email" for="email" />
                    <Input id="email" type="email" name="email" />
                </div>

                <div className="mb-3">
                    <Label text="Password" for="password" />
                    <Input id="password" type="password" name="password" />
                </div>
            </>
        )
    } 

    const viewMemberTableHeaders = ["Name", "Email"];

    return(
        <>
            <DashboardRightSideBarContainer type={DATA_TYPES.MEMBERS} title="Member" addDataFormFields={addMemberFormFields()} viewDataTableHeaders={viewMemberTableHeaders}/>
        </>
    )
}

export default Members;