import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
//import Loader from '../../../../components/loader';

function MemberContentDataTabs({tabs, activeTab, setActiveTab}) {

  const handleTabSelect = (selectedTab) => {
    console.log("selectedTab", selectedTab)
    setActiveTab(selectedTab);
    //debouncedAPICall(selectedTab); 
  }  

  return (
    <Tabs
      defaultActiveKey={activeTab}
      id="justify-tab-example"
      className="mb-3"
      justify
      //variant='underline'
      onSelect={handleTabSelect} // Handle tab change event
    >

      {tabs.map((tab, key) => 
        <Tab eventKey={tab} key={key} title={tab.toUpperCase()}>
            {/* <Loader /> */}
        </Tab>)
      }
    </Tabs>
  );
}

export default MemberContentDataTabs;