export const CONTENT_TYPES = {
    DOCUMENT: "document",
    VIDEO: "video",
    LIVE_STREAM: "live_stream",
    CATEGORY: "category"
}

export const DATA_TYPES = {
    ...CONTENT_TYPES,
    MEMBERS: "members"
}

export const CONTENT_SOURCE = {
    GOOGLE_DRIVE: "googledrive",
    YOUTUBE: "youtube"
}

export const USER_ROLES = {
    CREATOR: "creator",
    MEMBER: "member"
}

export const HTTP_STATUS_CODES = {
    OK: 200,
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    INVALID_TOKEN: 498,
}

export const PLAYER_FILE_URLS_PREFIX = {
    GOOGLE_DRIVE: "https://drive.google.com/uc?id=",
    YOUTUBE: "https://www.youtube.com/embed/"
}