export const messages = {
    error: {
        allFieldsRequired: "All fields are required.",

        invalidContentURL: "Invalid URL.", 
        strongPassword: "Your password must have at least 8 characters, 1 uppercase, 1 lowercase and 1 number.",

        dateTimePast: "Date and Time should be greater than current date and time.",
        invalidDuration: "Invalid duration.",

        message: "Sorry, something went wrong. Please try again..!!",
    }
}