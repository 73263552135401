import Swal from 'sweetalert2';
import { getQueryStringValue, verifyUser } from '../../../utils/functions';
import Loader from '../../../components/loader';
import { useCallback, useEffect, useState } from 'react';
import { HTTP_STATUS_CODES } from '../../../constants';
import { useNavigate } from 'react-router-dom';

const VerifyRegistration = () => {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const token = getQueryStringValue("token");

    // Get the query string from the URL
    if(!token){
        navigate("/404");
    }

    const verifyUserEmail = useCallback(async() => {
        const response = await verifyUser(token);
        if(response?.status === HTTP_STATUS_CODES.OK){
            Swal.fire({
                icon: 'success',
                title: response?.message,
                showConfirmButton: true,
                //timer: 1500
            }).then(() => {
                navigate("/creator/login");
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: response?.message,
                showConfirmButton: true,
                //timer: 1500
            }).then(() => {
                navigate("/creator/register");
            });
        }
        setIsLoading(false);
    },[setIsLoading, token, navigate])

    useEffect(() => {
        verifyUserEmail();
    }, [verifyUserEmail])

    return(
        <>
            {isLoading? <Loader />: <></>}
        </>
    )

}

export default VerifyRegistration;