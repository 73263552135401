import Pagination from 'react-bootstrap/Pagination';

const CustomPagination = ({ totalPages, currentPage, onPageChange }) => {

  const calculatePagesToShow = (totalPages) => {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1200) {
      return totalPages>10 ? 10: totalPages; // Show 10 pages for large screens
    } else if (screenWidth >= 992) {
      return totalPages>8 ? 8: totalPages; // Show 8 pages for medium screens
    } else if(screenWidth >= 410){
      return totalPages >6 ? 6: totalPages; // Show 6 pages for small screens and below
    } else if(screenWidth >= 315){
        return totalPages>4 ? 4: totalPages;
    } else {
        return totalPages>2 ? 3: totalPages;
    }
  }


  const pagesToShow = calculatePagesToShow(totalPages);

  const handlePageClick = (page) => {
    onPageChange(page);
  };

  const renderPageItems = () => {
    const pageItems = [];
    //const middle = Math.floor(pagesToShow / 2);
    const halfPagesToShow = Math.floor(pagesToShow / 2);

    let start = currentPage - halfPagesToShow;
    let end = currentPage + halfPagesToShow;

    if (start < 1) {
      start = 1;
      end = pagesToShow;
    }
    if (end > totalPages) {
      end = totalPages;
      start = end - pagesToShow + 1;
    }

    if (start > 1) {
      pageItems.push(
        <Pagination.First key={0} onClick={() => handlePageClick(1)} />
      );
      pageItems.push(<Pagination.Ellipsis key={-1} />);
    }

    for (let i = start; i <= end; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (end < totalPages) {
      pageItems.push(<Pagination.Ellipsis key={-2} />);
      pageItems.push(
        <Pagination.Last key={-3} onClick={() => handlePageClick(totalPages)} />
      );
    }

    return pageItems;
  };

  return (
    <Pagination className='justify-content-center p-0 m-0'>
      {renderPageItems()}
    </Pagination>
  );
};

export default CustomPagination;