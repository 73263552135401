import Label from '../../components/label';
import Input from '../../components/input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons';
import { handleUserLogin, getFormAllFieldsValues, isAnyFieldEmpty, isUserLoggedIn} from '../../utils/functions';
import { HTTP_STATUS_CODES, USER_ROLES } from '../../constants';
import Swal from "sweetalert2";
import { messages } from '../../utils/messages';
import {Navigate, useNavigate} from "react-router-dom";
import { useContext, useState } from 'react';
import { AuthContext } from '../../contextProviders/auth';
import Loader from "../../components/loader"

const Login = (props) => {

  //const userType = props?.type || "member";

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    if(isUserLoggedIn()){
        Navigate("/");
    }

    // useEffect(() => {
    //     if(isUserLoggedIn()){
    //         navigate("/");
    //     }
    // }, [navigate])

    const {setCurrentUser} = useContext(AuthContext);

    const handleLogin = async(e) => {
        e.preventDefault();
        
        const data = getFormAllFieldsValues(e);
        //data.user_role = userType;

        if(isAnyFieldEmpty(data)){
            Swal.fire({
                icon: 'error',
                title: messages.error.allFieldsRequired,
                showConfirmButton: true,
                //timer: 1500
            })
        } else {
            setIsLoading(true);
            const response = await handleUserLogin(data);
            if(response.status !== HTTP_STATUS_CODES.OK){
                Swal.fire({
                    icon: 'error',
                    title: response?.message || messages.error.message,
                    showConfirmButton: true,
                    //timer: 1500
                })
            } else{
                setCurrentUser(response?.data);                
                if(response?.data?.role === USER_ROLES.CREATOR){
                    navigate("/creator/dashboard");
                } else{
                    navigate("/member/dashboard");
                }
            }
            setIsLoading(false);
        }
    }

    return (
        <div className="container-fluid bg-primary text-center text-lg-start vh-80">
            <div className="row h-100 justify-content-center align-items-center mb-5 mh-100 overflow-y-auto">
                <div className="col-lg-6 mb-5 mb-lg-0" style={{zIndex: 10}}>
                    <h1 className="my-5 display-5 fw-bold ls-tight" style={{color: "hsl(218, 81%, 95%)"}}>
                        Welcome ..!!
                        <br />
                        <span style={{color: "hsl(218, 81%, 75%)"}}>Log in to your account</span>
                    </h1>
                    <p className="mb-4 opacity-70" style={{color: "hsl(218, 81%, 85%)"}}>
                        We're thrilled to have you here again. As always, your security is our top priority, and we've taken measures to ensure your account is in safe hands.
                        To connect, please login with your information by email id and password.
                    </p>
                </div>

                <div className="col-lg-6 mb-5 mb-lg-0 position-relative">

                    <div className="card bg-glass">
                        <div className="card-body px-4 py-5 px-md-5">
                            
                            <form onSubmit={handleLogin}>
                                
                                {/* <div className="form-outline mb-4">
                                    <FontAwesomeIcon icon={faEnvelope} /> &nbsp;
                                    <Label text="Username" for="username"/>
                                    <Input type="text" id="username" name="username" />
                                </div> */}

                                <div className="form-outline mb-4">
                                    <FontAwesomeIcon icon={faEnvelope} /> &nbsp;
                                    <Label text="Email" for="email"/>
                                    <Input type="email" id="email" name="email" />
                                </div>

                                
                                <div className="form-outline mb-4">
                                    <FontAwesomeIcon icon={faKey} /> &nbsp;
                                    <Label text="Password" for="password" />
                                    <Input type="password" id="password" name="password" />
                                </div>
                                {isLoading ? 
                                    <Loader />
                                :
                                    <button type="submit" className="btn btn-primary btn-block mb-4">
                                        Login
                                    </button>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;